import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { UploaderDirective } from './uploader.directive';
import { UploadSectionComponent } from './upload-section/upload-section.component';
import { ImageCropperModalComponent } from './image-cropper-modal/image-cropper-modal.component';
import { CropperComponent } from './cropper/cropper.component';
import { LogoUploaderComponent } from './logo-uploader/logo-uploader/logo-uploader.component';
import { ImagePositionComponent } from './image-position/image-position.component';
import { ProgressCircleComponent } from '@app/shared/components/progress-circle/progress-circle.component';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ModalFooterComponent } from '@app/shared/components/modal/modal-footer/modal-footer.component';

@NgModule({
  declarations: [
    UploaderDirective,
    FileUploaderComponent,
    ImageUploaderComponent,
    UploadSectionComponent,
    ImageCropperModalComponent,
    CropperComponent,
    LogoUploaderComponent,
    ImagePositionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    // standalone components
    ProgressCircleComponent,
    ModalComponent,
    ModalFooterComponent,
  ],
  exports: [
    FileUploaderComponent,
    ImageUploaderComponent,
    UploadSectionComponent,
    ImageCropperModalComponent,
    UploaderDirective,
    LogoUploaderComponent,
  ],
})
export class UploaderModule {}
