import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TagsRoutingModule } from './tags-routing.module';
import { ReplaceFocusInterestModal } from '@app/tags/components/replace-focus-interest-modal/replace-focus-interest-modal.component';
import { CheckpointRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/checkpoint-rating-button.component';
import { CredentialRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/credential-rating-button.component';
import { EvaluationRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/evaluation-rating-button.component';
import { ExternalRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/external-rating-button.component';
import { ManagerRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/manager-rating-button.component';
import { PeerRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/peer-rating-button.component';
import { SelfRatingButtonComponent } from '@app/tags/components/tag-rating-button/buttons/self-rating-button.component';
import { TagRatingButtonComponent } from '@app/tags/components/tag-rating-button/tag-rating-button.component';
import { UpdateSelfRatingComponent } from '@app/tags/components/update-self-rating/update-self-rating.component';
import { EvaluationService } from '@app/tags/services/evaluation.service';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { UserModule } from '@app/user/user.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AddTagsModalComponent } from './components/add-tags-modal/add-tags-modal.component';
import { AddTagsConvertCanonicalModalComponent } from './components/add-tags-convert-canonical-modal/add-tags-convert-canonical-modal.component';
import { CancelTagRatingRequestModalComponent } from './components/cancel-tag-rating-request-modal/cancel-tag-rating-request-modal.component';
import { PeerRatingOverviewModalComponent } from './components/peer-rating-overview-modal/peer-rating-overview-modal.component';
import { RatingButtonMetaComponent } from './components/rating-button-meta/rating-button-meta.component';
import { TagRatingModalComponent } from './components/tag-rating-modal/tag-rating-modal.component';
import { TagRatingModalMultiModeComponent } from './components/tag-rating-modal-multi-mode/tag-rating-modal-multi-mode.component';
import { RequestSelfRatingModalComponent } from './components/request-self-rating-modal/request-self-rating-modal.component';
import { TagCardResourceComponent } from './components/tag-card-resource/tag-card-resource.component';
import { TagCardComponent } from './components/tag-card/tag-card.component';
import { TagCheckpointRatingsModalComponent } from './components/tag-checkpoint-ratings-modal/tag-checkpoint-ratings-modal.component';
import { TagCompletedAssociateRatingModalComponent } from './components/tag-completed-associate-rating-modal/tag-completed-associate-rating-modal.component';
import { TagLevelDescriptionModalComponent } from './components/tag-level-description-modal/tag-level-description-modal.component';
import { TagManagerRatingRequestModalComponent } from './components/tag-manager-rating-request-modal/tag-manager-rating-request-modal.component';
import { TagPeerRatingRequestModalComponent } from './components/tag-peer-rating-request-modal/tag-peer-rating-request-modal.component';
import { TagPeerSearchComponent } from './components/tag-peer-search/tag-peer-search.component';
import { TagPillComponent } from './components/tag-pill/tag-pill.component';
import { TagPrivacyDropdownComponent } from './components/tag-privacy-dropdown/tag-privacy-dropdown.component';
import { TagProviderLogoComponent } from './components/tag-provider-logo/tag-provider-logo.component';
import { TagPublicRatingModalComponent } from './components/tag-public-rating-modal/tag-public-rating-modal.component';
import { TagRatingAddIntegrationRatingsSuccessModalComponent } from './components/tag-rating-add-integration-ratings-success-modal/tag-rating-add-integration-ratings-success-modal.component';
import { TagRatingCheckupTipComponent } from './components/tag-rating-checkup-tip/tag-rating-checkup-tip.component';
import { TagRatingDisplayComponent } from './components/tag-rating-display/tag-rating-display.component';
import { TagRatingDonutComponent } from './components/tag-rating-donut/tag-rating-donut.component';
import { TagRatingEndorsementCompletedModalComponent } from './components/tag-rating-endorsement-completed-modal/tag-rating-endorsement-completed-modal.component';
import { TagRatingExternalDisplayComponent } from './components/tag-rating-external-display/tag-rating-external-display.component';
import { TagRatingLevelRingComponent } from './components/tag-rating-level-ring/tag-rating-level-ring.component';
import { TagRatingLevelPercentageRingComponent } from './components/tag-rating-level-percentage-ring/tag-rating-level-percentage-ring.component';
import { TagRatingMoreCountComponent } from './components/tag-rating-more-count/tag-rating-more-count.component';
import { TagRatingOverviewComponent } from './components/tag-rating-overview/tag-rating-overview.component';
import { TagRatingProviderLabelComponent } from './components/tag-rating-provider-label/tag-rating-provider-label.component';
import { TagRatingProviderLevelComponent } from './components/tag-rating-provider-level/tag-rating-provider-level.component';
import { TagRatingReviewIntegrationRatingsModalComponent } from './components/tag-rating-review-integration-ratings-modal/tag-rating-review-integration-ratings-modal.component';
import { TagRatingSignalPopoverContentComponent } from './components/tag-rating-signal-popover-content/tag-rating-signal-popover-content.component';
import { TagRatingSignalComponent } from './components/tag-rating-signal/tag-rating-signal.component';
import { TagRatingSliderComponent } from './components/tag-rating-slider/tag-rating-slider.component';
import { TagRatingSummaryBreakdownModalComponent } from './components/tag-rating-summary-breakdown/tag-rating-summary-breakdown-modal/tag-rating-summary-breakdown-modal.component';
import { TagRatingSummaryBreakdownComponent } from './components/tag-rating-summary-breakdown/tag-rating-summary-breakdown.component';
import { TagRecommendationModalComponent } from './components/tag-recommendation-modal/tag-recommendation-modal.component';
import { TagRecommendationViewModalComponent } from './components/tag-recommendation-view-modal/tag-recommendation-view-modal.component';
import { TagSearchListComponent } from './components/tag-search-list/tag-search-list.component';
import { TagTileFooterComponent } from './components/tag-tile/tag-tile-footer/tag-tile-footer.component';
import { TagTileRatingDescriptionComponent } from './components/tag-tile/tag-tile-rating-description/tag-tile-rating-description.component';
import { TagTileTargetRatingComponent } from './components/tag-tile/tag-tile-target-rating/tag-tile-target-rating.component';
import { TagTileComponent } from './components/tag-tile/tag-tile.component';
import { TagsEditComponent } from './components/tags-edit/tags-edit.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { TagsSearchComponent } from './components/tags-search/tags-search.component';
import { TagModalService } from './services/tag-modal.service';
import { TagsRecommendationService } from './services/tags-recommendation.service';
import { TagResourceViewComponent } from './components/tag-resource-view/tag-resource-view.component';
import { AddSuggestedTagsModalComponent } from '@app/tags/components/add-suggested-tags-modal/add-suggested-tags-modal.component';
import { TagRecommendationTileComponent } from './components/tag-recommendation-tile/tag-recommendation-tile.component';
import { DfFormlyModule } from '@lib/fresco';
import { TagsSharedModule } from './tags-shared.module';
import { TagRatingModalChangeRatingComponent } from './components/tag-rating-modal-change-rating/tag-rating-modal-change-rating.component';
import { TagSuggestionsComponent } from './components/tag-suggestions/tag-suggestions.component';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';
import { ExpandingTitleSectionComponent } from '@app/shared/components/expanding-title-section/expanding-title-section.component';
import { IconsModule } from '@degreed/apollo-angular';
import { ModalComponent } from '@app/shared/components/modal/modal.component';

@NgModule({
  imports: [
    SharedModule,
    NgbDropdownModule,
    UserModule,
    NgxSliderModule,
    TagsRoutingModule,
    DfFormlyModule,
    TagsSharedModule,

    //standalone components
    AutofocusDirective,
    ExpandingTitleSectionComponent,
    IconsModule,
    ModalComponent,
  ],
  declarations: [
    /* Components  */
    TagRatingDonutComponent,
    TagRatingLevelRingComponent,
    TagRatingLevelPercentageRingComponent,
    PeerRatingOverviewModalComponent,
    RatingButtonMetaComponent,
    TagRatingModalComponent,
    TagRatingModalMultiModeComponent,
    TagRatingModalChangeRatingComponent,
    ReplaceFocusInterestModal,
    RequestSelfRatingModalComponent,
    TagPillComponent,
    TagTileComponent,
    TagsEditComponent,
    TagsListComponent,
    TagsSearchComponent,
    TagTileFooterComponent,
    TagTileRatingDescriptionComponent,
    TagTileTargetRatingComponent,
    TagRatingOverviewComponent,
    TagRatingDisplayComponent,
    TagSearchListComponent,
    TagCardComponent,
    TagProviderLogoComponent,
    TagCardResourceComponent,
    TagRatingSignalComponent,
    TagRatingSliderComponent,
    TagRatingProviderLabelComponent,
    TagRatingProviderLevelComponent,
    TagRatingMoreCountComponent,
    TagRatingSignalPopoverContentComponent,
    TagRecommendationModalComponent,
    TagRecommendationViewModalComponent,
    TagPrivacyDropdownComponent,
    TagPublicRatingModalComponent,
    TagManagerRatingRequestModalComponent,
    TagRatingAddIntegrationRatingsSuccessModalComponent,
    TagRatingReviewIntegrationRatingsModalComponent,
    TagCompletedAssociateRatingModalComponent,
    TagRatingExternalDisplayComponent,
    TagLevelDescriptionModalComponent,
    TagRatingEndorsementCompletedModalComponent,
    TagRatingCheckupTipComponent,
    TagPeerRatingRequestModalComponent,
    AddTagsModalComponent,
    TagPeerSearchComponent,
    UpdateSelfRatingComponent,
    CancelTagRatingRequestModalComponent,
    TagCheckpointRatingsModalComponent,
    TagRatingSummaryBreakdownModalComponent,
    TagRatingSummaryBreakdownComponent,
    TagRatingButtonComponent,
    SelfRatingButtonComponent,
    ManagerRatingButtonComponent,
    CredentialRatingButtonComponent,
    EvaluationRatingButtonComponent,
    PeerRatingButtonComponent,
    ExternalRatingButtonComponent,
    CheckpointRatingButtonComponent,
    TagResourceViewComponent,
    AddSuggestedTagsModalComponent,
    TagRecommendationTileComponent,
    AddTagsConvertCanonicalModalComponent,
    TagSuggestionsComponent,
  ],
  providers: [
    /* Services */
    TagActionOptionsService,
    TagModalService,
    TagsRecommendationService,
    TagRatingService,
    EvaluationService,
  ],
  exports: [
    /* Components  */
    TagCardComponent,
    TagPillComponent,
    TagRatingDonutComponent,
    TagRatingLevelRingComponent,
    TagRatingLevelPercentageRingComponent,
    TagRatingProviderLabelComponent,
    TagRatingProviderLevelComponent,
    RatingButtonMetaComponent,
    TagRatingModalComponent,
    TagRatingModalMultiModeComponent,
    TagRatingModalChangeRatingComponent,
    TagTileComponent,
    TagsEditComponent,
    TagsListComponent,
    TagProviderLogoComponent,
    TagRecommendationModalComponent,
    TagRecommendationViewModalComponent,
    TagsSearchComponent,
    TagSearchListComponent,
    TagRatingSignalPopoverContentComponent,
    TagRatingSignalComponent,
    TagRatingSliderComponent,
    TagRatingCheckupTipComponent,
    TagRatingSummaryBreakdownComponent,
    TagRatingButtonComponent,
    SelfRatingButtonComponent,
    ManagerRatingButtonComponent,
    CredentialRatingButtonComponent,
    EvaluationRatingButtonComponent,
    PeerRatingButtonComponent,
    ExternalRatingButtonComponent,
    CheckpointRatingButtonComponent,
    TagRecommendationTileComponent,
    TagSuggestionsComponent,
    TagPrivacyDropdownComponent,
  ],
})
export class TagsModule {}
