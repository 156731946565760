import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../../../../../apollo/src/icons/icons.module';
import { ModalComponent } from '../modal.component';
import { CommonModule } from '@angular/common';

export interface AlertModalInputs {
  title: string;
  description?: string;
  hideCancel?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  useApolloStyles?: boolean;
}

@Component({
  selector: 'dgx-alert-modal',
  templateUrl: './alert-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ModalComponent, IconsModule],
})
export class AlertModalComponent {
  @Input() public title: string;
  @Input() public description?: string;
  @Input() public hideCancel?: boolean;
  @Input() public confirmButtonText?: string;
  @Input() public cancelButtonText?: string;
  @Input() public useApolloStyles = false;

  constructor(private activeModal: NgbActiveModal) {}

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public onConfirm() {
    this.activeModal.close();
  }
}
