import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { DfFormlyModule } from '@lib/fresco';
import { RecommendationsDetailModalComponent } from './components/recommendations-detail-modal/recommendations-detail-modal.component';
import { SharedWithUserListComponent } from './components/shared-with-user-list/shared-with-user-list.component';
import { ShareTabsModalComponent } from './components/share-tabs-modal/share-tabs-modal.component';
import { RecommendationsStatusComponent } from './components/recommendations-status/recommendations-status.component';
import { RecommendationUpdateModalComponent } from './components/recommendation-update-modal/recommendation-update-modal.component';
import { MediumDateParserFormatterService } from '@app/shared/services/date-picker/medium-date-parser-formatter.service';
import { RecommendationFormComponent } from './components/recommendation-form/recommendation-form.component';
import { UserModule } from '@app/user/user.module';
import { TagsModule } from '@app/tags/tags.module';
import { NavigationModule } from '@app/navigation/navigation.module';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';

// upgraded ajs components
import { ShareWithGroupModalComponent } from './components/share-with-group-modal/share-with-group-modal.component';
import { TrackingLinkFormComponent } from './components/tracking-link-form/tracking-link-form.component';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ModalFooterComponent } from '@app/shared/components/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '@app/shared/components/modal/modal-header';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UserModule,
    TagsModule, // only for tag rating donut in recommendation form
    NavigationModule,
    DfFormlyModule.forRoot(),

    // standalone components
    AutofocusDirective,
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
  ],
  providers: [DatePipe, MediumDateParserFormatterService],
  exports: [
    RecommendationsDetailModalComponent,
    RecommendationsStatusComponent,
    SharedWithUserListComponent,
  ],
  declarations: [
    RecommendationFormComponent,
    RecommendationsDetailModalComponent,
    SharedWithUserListComponent,
    ShareTabsModalComponent,
    RecommendationsStatusComponent,
    RecommendationUpdateModalComponent,
    ShareWithGroupModalComponent,
    TrackingLinkFormComponent,
  ],
})
export class RecommendationsModule {}
