import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToPathwayComponent } from './components/add-to-pathway/add-to-pathway.component';
import { ApolloAngularModule } from '@degreed/apollo-angular';
import { DfSpinnerButtonModule } from '@lib/fresco';
import { SharedModule } from '@app/shared/shared.module';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ModalFooterComponent } from '@app/shared/components/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '@app/shared/components/modal/modal-header';

@NgModule({
  imports: [
    ApolloAngularModule,
    CommonModule,
    DfSpinnerButtonModule,
    SharedModule,

    // standalone modules
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
  ],
  declarations: [AddToPathwayComponent],
  exports: [AddToPathwayComponent],
  providers: [],
})
export class PathwayModule {}
