import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DfLabelMarkerModule } from '@lib/fresco';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../modal.component';

export interface DeleteModalInputs {
  /** Displayed confirmation text, label of the input */
  deleteInstructions: string;
  /** Title of the modal */
  title: string;
  /** Title of the modal header*/
  modalHeaderTitle?: string;
  /** Overwrite the text on the confirmation button, defaults to 'Confirm' */
  confirmButtonText?: string;
  /** Description shown under title of the modal, defaults to empty string */
  description?: string;
  /** Description CSS classes added to description paragraph element */
  descriptionClasses?: string;
  /** List of item names to display as a ul under the description for use in bulk delete  */
  itemNames?: string[];
  /** Phrase the user needs to type in to confirm deletion, defaults to 'DELETE' */
  deletePhrase?: string;
}

/**
 * This component is the migrated version of the `promptDeleteDialogCtrl`
 * ajs component.
 *
 * The old ajs `promptDeleteDialogCtrl` component is still being used by
 * the ajs app.
 *
 * Once all of the parent/container ajs components that depend on `promptDeleteDialogCtrl`
 * are migrated over to ngx, the `promptDeleteDialogCtrl` component can then be removed from
 * ajs and replace with this component which is launched from the `modal.service.showDeleteConfirmation`
 * service.
 */
@Component({
  selector: 'dgx-delete-modal',
  templateUrl: './delete-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalComponent,
    DfLabelMarkerModule,
  ],
})
export class DeleteModalComponent implements OnInit {
  @Input() public deleteInstructions: string;
  @Input() public title: string;
  @Input() public modalHeaderTitle: string;
  @Input() public confirmButtonText?: string;
  @Input() public deletePhrase?: string;
  @Input() public description?: string;
  @Input() public descriptionClasses?: string;
  @Input() public itemNames: string[];
  /** Translated text to be read aloud by the submit button, giving further context to the button's action. *Default undefined.* */
  @Input() public submitButtonAriaLabel: string = undefined;

  public i18n: Record<any, string> = this.translate.instant([
    'TargetCtrl_ConfirmDeleteButton',
  ]);
  public deleteForm = this.formBuilder.group(
    {
      deleteConfirm: ['', [Validators.required]],
    },
    {
      // make sure that the delete phrase matches
      validator: (fg: FormGroup) => {
        const valid = fg.get('deleteConfirm').value === this.deletePhrase;

        return valid ? null : { invalid: true };
      },
    }
  );
  private readonly defaultDeletePhrase = 'DELETE';

  constructor(
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.deletePhrase = this.deletePhrase || this.defaultDeletePhrase;
    this.confirmButtonText =
      this.confirmButtonText || this.i18n.TargetCtrl_ConfirmDeleteButton;
  }

  public onSubmit(): void {
    if (this.deleteForm.invalid) {
      return;
    }

    this.activeModal.close(true);
  }
}
